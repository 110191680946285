import React from 'react';
import { graphql } from 'gatsby';

import JustComments from 'gatsby-plugin-just-comments';

import classNames from "classnames";
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import TextField from '@material-ui/core/TextField';
import './blogTemplateModule/blogTemplate.module.scss';
import blogTemplateModule from './blogTemplateModule/blogTemplate.module.scss';

import '../assets/jss/material-kit-react/views/blogTemplateStyle.jsx';
import blogTemplateStyle from "assets/jss/material-kit-react/views/blogTemplateStyle.jsx";
import BlogSubscribeSection from '../pages/LandingPage/Sections/BlogSubscribeSection';


export const query = graphql`
    query (
        $slug: String!
    ) {
        markdownRemark (
        fields: {
            slug: {
            eq: $slug
            }
        }
        ){
        frontmatter{
            title
            date (formatString: "MMM DD, YYYY")
        }
        html
        }
    }
`
const dashboardRoutes = [];


const Blog = (props) => {
    return(
        <div>
            <div>
                <Header
                    color="#009688" //made it #009688, don't know why
                    routes={dashboardRoutes}
                    brand="BookMerlin"
                    rightLinks={<HeaderLinks />}
                    fixed
                    changeColorOnScroll={{
                        height: 200,
                        color: "#009688" //changed from white
                    }}
                />
            </div> 
                <div> 
                    <div className={classNames(blogTemplateModule.main, blogTemplateStyle.mainRaised)}>
                        <div className={blogTemplateStyle.container}>
                            <GridContainer justify="center">
                                <GridItem >
                                    <div  className={blogTemplateModule.header}>
                                        <h2 className={blogTemplateModule.title}>{props.data.markdownRemark.frontmatter.title}</h2>
                                        <div className={blogTemplateModule.headerTwo}>
                                            <img src={require('components/Header/img/logo.png')} 
                                                className={blogTemplateModule.img}
                                            />
                                            <h4 className={blogTemplateModule.date}>{props.data.markdownRemark.frontmatter.date}</h4>
                                        </div>                                   
                                    </div>
                                    <div 
                                        dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html}} 
                                        className={blogTemplateModule.content}
                                    >
                                    </div>
                                </GridItem>
                                <GridItem className={blogTemplateModule.blogSubscribe}>
                                    <BlogSubscribeSection />
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    )
}

export default withStyles(blogTemplateStyle)(Blog);


/*

<GridItem className={blogTemplateModule.leaveComment}>
    <TextField 
        placeholder="Leave your comment here..."
        margin="normal"
        variant='outlined'
        fullWidth
    />
    <Button>
        Submit
    </Button>
</GridItem>
*/